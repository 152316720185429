const _ = require("lodash")

// function getArrayNodeGraphql(array, addObj = {}) {
//     let key = Object.keys(array.data)[0]
//     let _array = array.data[key].edges.map(_edge => ({ ..._edge.node, ...addObj }))
//     return _array
// }

function getArrayNodeGraphql(array, addObj = {}) {
    let _array = array.data.map(data => ({ ...data, ...addObj }))
    return _array
}


function numberWithCommas(text) {
    if (text) {
        return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else {
        return text
    }

}

module.exports = {
    getArrayNodeGraphql,
    numberWithCommas
}